







import axios from 'axios'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class About extends Vue {
  public greetings = 'Hy there'

  public mounted () {
    this.fetchReasons()
  }

  public async fetchReasons () {
    // `${apiUrl}/api/v1/${countrycode}/blacklist/reasons`,
    // { headers: authHeaders(token) },
    axios
      .get('http://127.0.0.1:8000/')
      .then(response => response.data)
      .then(text => (this.greetings = text.message))
    // .catch((error) => dispatchHandleRequestError(this.$store, error))
  }
}
